<template>
    <div class="main-pdding">
        <div class="main-box">
            <div class="main-between">
                <div class="title">
                    <label>公告管理</label>
                </div>
                <div class="new-product">
                    <el-button type="primary" icon="el-icon-plus" @click="goAdd">添加公告</el-button>
                </div>
            </div>
            <div class="search">
                <el-form inline :model="searchData" label-width="80px">
                    <el-form-item >
                        <div class="search-input">
                            <el-input v-model="searchData.name" placeholder="请输入搜索关键词" suffix-icon="el-icon-search"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="searchInfo">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="info-table">
                <infoTable :selection="false" :tableLoading="tableLoading" :tableHeadConfig="tableHead" :tableLoadData="tableLoadData">
                    <template v-slot:sort="slotData">
                        <span>{{ slotData.data.row.sort }}</span>
                    </template>
                    <template v-slot:status="slotData">
                        <el-switch v-model="slotData.data.row.status" :active-value="1" :inactive-value="0" @change="stateChange(slotData.data.row)">
                        </el-switch>
                    </template>
                    <template v-slot:operation="slotData">
                        <div class="operation-sty">
                            <span @click="goEdit(slotData.data.row)"> 编辑 </span>
                            <span @click="delGood(slotData.data.row)"> 删除 </span>
                        </div>
                    </template>
                </infoTable>
            </div>

            <div class="bottom-pagination">
                <info-page @pageclcik="pageclcik" :pagination="pagination"></info-page>
            </div>
        </div>

        <deldialog :appId="notId" title="是否确定要删除该公告?" @dialogChange="delChange" :dialogVal="delDialog"></deldialog>
    </div>
</template>

<script>
import { sessionGetKey } from "@/utils/sessionStorage.js";
export default {
    mounted() {
        this.platformId = sessionGetKey("platformId")
        this.getNotice()
    },
    data() {
        return {
            notId:'',
            delDialog:false,
            platformId: "",
            pagination: {
                currentpage: 1,
                total: 1,
                totalpage: 1,
                pageSize: 8,
            },
            searchData: {
                name: ""
            },
            tableLoading: false,
            tableHead: [
                {
                    label: "ID",
                    field: "id",
                    sort: true,
                },
                {
                    label: "排序",
                    field: "sort",
                    sort: true,
                    columnType: "slot",
                    slotName: "sort",
                },
                {
                    label: "标题",
                    field: "title"
                },
                {
                    label: "浏览量",
                    field: "visits"
                },
                {
                    label: "发布时间",
                    field: "time",
                    width: 200,
                },
                {
                    label: "状态",
                    columnType: "slot",
                    slotName: "status",
                },
                {
                    label: "操作",
                    columnType: "slot",
                    slotName: "operation",
                },
            ],
            tableLoadData: [],
        };
    },
    methods: {
        searchInfo(){
            this.pagination.currentpage = 1
            this.getNotice()
        },
        // 获取公告
        getNotice(){
            const that = this;
            that.tableLoading = true
            that.$request({
                url: that.$api.notice.getNot,
                method: "post",
                data:{
                    page:that.pagination.currentpage,
                    name:that.searchData.name
                }
            }).then((res) => {
                that.pagination.currentpage = res.data.current_page
                that.pagination.total = res.data.total
                that.pagination.pageSize =res.data.per_page
                that.pagination.totalpage = res.data.last_page
                that.tableLoadData = res.data.data
                that.tableLoading = false
            });
        },
        // 是否上下架
        stateChange(row) {
            const that = this
            console.log(row,'row');
            that.$request({
                url: that.$api.notice.editNot,
                method: "post",
                data:{
                    id:row.id,
                    status:row.status
                }
            }).then((res) => {
                that.getNotice()
            });
        },
        //分页器
        pageclcik(e) {
            if (e) {
                this.pagination.currentpage = e;
                this.getNotice();
            }
        },
        goAdd() {
            this.$router.push({
                path: "/notice/notList/addNot",
                query: {type:'1'}
            });
        },
        goEdit(row) {
            this.$router.push({ path: "/notice/notList/addNot", query: { data:JSON.stringify(row),type:'2'} });
        },
        // 删除公告
        delGood(item) {
            this.notId = item.id
            this.delDialog = true
        },
        delChange(type, id) {
            this.delDialog = false;
            console.log(type,id,'dididi');
            if (type === 1) {
                this.$request({
                    url: this.$api.notice.delNot,
                    method: "post",
                    data: {
                        id: id,
                    },
                }).then((res) => {
                    this.getNotice();
                });
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.title {
    label {
        display: inline-block;
        height: 30px;
        color: #4458fe;
        font-size: 20px;
        font-weight: 600;
        border-bottom: 2px solid #4458fe;
    }
}

.search {
    margin-top: 15px;
    .search-input {
        width: 400px;
        border-radius: 6px;
        border: 1px solid #999;
        /deep/.el-input__inner {
            border: 0 !important;
        }
    }
}

.info-table {
    min-height: 400px;
    .operation-sty {
        span {
            color: #4458fe;
            cursor: pointer;
        }

        span:before {
            content: "|";
            margin: 0 5px;
            color: #4458fe;
        }

        span:first-child:before {
            display: none;
        }
    }
}

.bottom-pagination {
    margin-top: 10px;
}
</style>
